import AxiosLib from "@/apis/Axios/AxiosLib";
import ApisLang from "@/locales/Apis/ApisLang";

const url = 'AgentApi/Agent.';
const apis = {
    ...AxiosLib,

    /**
     * 生成地址
     * @param page [页面名字]子模块名
     * @param name 功能名
     * @return {string}
     * 返回完整Url
     */
    MakeUrl(page,name) {
        return `${url}${page}/${name}`;
    },

    /**
     * [取下级代理商列表]
     * 本接口会自动排序代理商账号
     * 并自动识别是否支持跨级
     * @return {*}
     */
    getSubordinateAgent(agentDataType){
        return AxiosLib.post(this.MakeUrl('AgentData','getSubordinateAgent'),{agentDataType},true,false);
    },

    /**
     * 获取自身余额
     */
    getSelfMoney(){
        return AxiosLib.post(this.MakeUrl('AgentData','getSelfMoney'),{},true,false);
    },

    /**
     * 取下级代理商列表
     * @param agentDataType 1=显示全部代理商[跨级] 2=只显示下一级代理商
     * @return {*}
     */
    getAgentList(agentDataType){
        return AxiosLib.post(
            this.MakeUrl('AgentData','getAgentList')
            , {agentDataType},
            true,false,
        );
    },

    //添加下级代理
    addAgent(username,password,name,contact){
        let fieldCodeLang = ApisLang('Agent','addAgent');
        return AxiosLib.post(
            this.MakeUrl('Agent','addAgent')
            , {username,password,name,contact},
            true,true,
            fieldCodeLang
        );
    },

    //取指定代理商信息
    getAgentData(agentId){
        return AxiosLib.post(
            this.MakeUrl('AgentData','getAgentData')
            , {agentId},
            true,false,
        );
    },

    //修改代理商多个信息
    setAgentInfo(agentId,setInfo){
        let fieldCodeLang = ApisLang('Agent','setAgentInfo');
        return AxiosLib.post(
            this.MakeUrl('AgentData','setAgentInfo')
            , {agentId,setInfo},
            true,true,
            fieldCodeLang
        );
    },

    //修改代理商多个信息
    changeMoney(agentId,changeType,money,moneyType){
        let fieldCodeLang = ApisLang('Agent','changeMoney');
        return AxiosLib.post(
            this.MakeUrl('Money','changeMoney')
            , {agentId,changeType,money,moneyType},
            true,true,
            fieldCodeLang
        );
    },

    //获取下级代理商用户
    getAgentUserList(agent){
        return AxiosLib.post(this.MakeUrl('AgentData','getAgentUserList'),{agent},true,false);
    },

    /**
     * 查询指定代理商日志
     * @param agent
     * @param langType [语言]1=中文 2=英文
     * @return {*}
     */
    getAgentLog(agent,langType){
        return AxiosLib.post('AgentApi/AgentLog.Log/getAgentLogNew',{agent,langType},true,false);
    },

    //更改自己密码
    setSelfNewPass(newPass){
        return AxiosLib.post(this.MakeUrl('AgentData','setSelfNewPass'),{newPass},true,true);
    },

    /**
     * 下级代理用户.回迁用户
     * @param userList 要迁移的用户[多个]
     * @param agent 下级代理商账号
     * @return {*}
     */
    moveUserByLowAgent(userList,agent){
        let fieldCodeLang = ApisLang('Agent','moveUserByLowAgent');
        return AxiosLib.post('AgentApi/User.UserMove/moveUserByLowAgent',{
            userList,agent
        }, true, true,fieldCodeLang);
    },
};
export default apis;