import LangsUtil from "@/utils/Lang/LangsUtil";

/**
 * 通用,取出多语言数据
 */

/**
 * 取出多语言
 * @param LangsObj [对象] 两个属性 zh与en
 * @param Module 模块名
 * @param Name 语言节点名字
 * @return {{}}
 * [返回对象]成功返回语言对象 失败返回空
 */
const getApisLang = (LangsObj,Module,Name) => {
    let res = LangsUtil(LangsObj,Module);
    return res[Name] || {};
}
export default getApisLang;