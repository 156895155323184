const Agent = {
    AddAgent:{
        template:{
            HeaderNavBar:'添加下级',
            vanField:{
                username:'账号',
                password:'密码',
                name:'名字',
                contact:'联系方式',
            },
            vanButton:{
                submit:'提交',
                RefreshPage:'刷新',
            },
        },
        setup:{},
    },
    AgentLog:{
        template: {
            HeaderTitle:'代理日志',
        },
        setup:{
            TableEvent:'暂无更多',
        }
    },
    AgentUserList:{
        template:{
            HeaderTitle:'下级用户管理',
        },
        setup:{
            actions:{
                1:'回迁用户'
            },
            getUserList:{
                tips:'已加载用户数量:',
            },
        }
    },
    AgentUserListFetchUser:{
        setup:{
            onMounted:{
                error:'所属下级代理账号异常',
            },
            InputTextOptions:{
                Name:'回迁用户',
            },
        }
    },
    AgentList:{
        template:{
            HeaderTitle:'代理列表',
        },
        setup:{
            AgentListMenuEvent:{
                length:'搜索结果为空',
            }
        }
    },
    //AgentList的子文件夹
    AgentListMenu:{
        setup:{
            actions:{
                1:'查看列表',
                2:'清空列表',
                3:'筛选数据',
                4:'添加下级',
            },
            FilterTextActions:{
                username:'账号',
                name:'名字',
                nickname:'备注',
            },
            FilterTimeConfig:{
                name:'注册时间',
            }
        }
    },
    AgentListTools:{
        setup:{
            actions:{
                1:'编辑信息',
                2:'充值余额',
                3:'回收余额',
                4:'查询卡密',
                5:'用户管理',
                6:'日志查询',
            }
        },
    },
    // AgentList子文件夹下的AgentList-Tools目录
    AgentToolsChangeMoney:{
        template:{
            vanField: {
                UserName:'账号',
                money:'操作金额',
                changeType:{
                    label:'操作类型',
                    1:'充值下级',
                    2:'回收下级',
                },
                moneyType:{
                    label:'金额类型',
                    1:'(余额)注册',
                    2:'(余额)充值',
                },
            },
            vanTag:{
                GetAgentMoneyStr:'下级信息',
                reg:'剩余额度:[注册]',
                rec:'[充值]',
            },
        },
        setup:{
            GetAgentMoneyStr:{
                reg:'注册:',
                rec:'充值:',
            }
        },
    },
    AgentToolsInfo:{
        template:{
            vanField:{
                status:{
                    label:'状态',
                    1:'启用',
                    2:'禁用',
                }
            }
        },
        setup:{
            FieldLabel:{
                username:'账号',
                password:'密码',
                leader:'所属',
                name:'名字',
                nickname:'备注',
                contact:'联系',
                money_rec:'充值',
                money_reg:'注册',
                login_time:'登录',
                reg_time:'注册',
                status:'状态',
            },
            confirm:{
                tips:'未做修改,无需提交'
            }
        }
    }
}
export default Agent