import LangClass from "@/utils/Lang/LangClass";

import zh_Agent from './zh/Agent';
import zh_Common from './zh/Common'
// 英文词库
// import en_Agent from './en/Agent';

const ViewLang = {
    zh:{
        Agent:zh_Agent,
        Common:zh_Common,
    },
    en:{
        Agent:zh_Agent,
        Common:zh_Common,
    }
}
export default (Module,Name)=>{
    return LangClass(ViewLang,Module,Name);
}