const Table = {
    TableFilter:{
        template:{
            submit:'提交',
            quit:'取消',
        },
        setup:{
            onSubmit:{
                error:{
                    filter:'请输入要筛选的条件',
                },
                success:'筛选完成,结果数量:',
            },
            onConfirm:{
                error:{
                    arrTime:'选择时间异常',
                    time:'选择时间异常',
                },
            }
        }
    },
    TableSetList:{
        setup:{
            GetCacheUserList:{
                length:'列表数据为空',
            },
            ClickUser:{
                title:'二次确认',
                message:'是否从列表删除',
                error:'删除发生异常情况',
            }
        }
    },
    TableUser:{
        setup:{
            HeaderOnSelect:{
                tips:'请选择要添加的项目'
            }
        }
    },

    //Table组件下的Table子文件夹
    TableHeader:{
        setup:{
            actions:{
                success:'全选',
                cross:'取消',
                plus:'添加',
                circle:'刷新',
                apps_o:'更多',
            }
        },
    },
}
export default Table