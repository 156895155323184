const Common = {
    Login:{
        template: {
            vanField:{
                username:'账号',
                password:'密码',
            },
            vanButton: {
                primary:'登录',
                default:'重置',
            }
        },
        setup:{
            formRule:{
                message:'请填写完整信息',
            },
            IdentifyDevice:{
                title:'询问',
                message:'是否需要跳转到PC端代理端?',
            }
        }
    },
    // Login组件的子文件夹
    Login_SetLanguage:{
        template:{
            cellTitle:'设置语言',
            cancel_text:'取消设置',
        },
        setup:{}
    },
    Result:{
        template:{
            HeaderNavBar:'执行结果',
        },
        setup:{
            onMounted:{
                tips:{
                    arr:'加载结果数据异常',
                    notify:'已完成执行',
                }
            }
        }
    },
}
export default Common