import LangClass from "@/utils/Lang/LangClass";

import zh_Form from './zh/Form';
import zh_Info from './zh/Info';
import zh_Table from './zh/Table';
import zh_TableNetWork from './zh/TableNetWork';
import zh_Time from './zh/Time';
//英文
// import en_Form from './en/Form';
const ComponentsLang = {
    zh:{
        Form:zh_Form,
        Info:zh_Info,
        Table:zh_Table,
        TableNetWork:zh_TableNetWork,
        Time:zh_Time,
    },
    en:{
        Form:zh_Form,
        Info:zh_Info,
        Table:zh_Table,
        TableNetWork:zh_TableNetWork,
        Time:zh_Time,
    }
};

export default (Module,Name)=>{
    return LangClass(ComponentsLang,Module,Name);
}