/**
 * 缓存数据[最大存储4k]
 * 关闭浏览器数据则会自动清除
 */
import CookieLib from "@/utils/Cache/common/CookieLib";

const _getField = (field) => {
    return 'CacheData-' + field
}

const CacheData = {
    /**
     * 读取一条缓存
     * @param field
     * @return {*}
     * 成功返回一个对象
     */
    GetData(field){
        let res = CookieLib.getCookie(_getField(field));
        if (res){
            let obj = JSON.parse(res);
            return obj.data ?? null;
        }
        return res;
    },

    /**
     * 保存缓存
     * @param field 字段名
     * @param data [对象]保存的数据
     * @return {string}
     * @constructor
     */
    SaveData(field,data){
        let res = JSON.stringify({
            data,
        });
        return CookieLib.SetCookie(_getField(field),res)
    },
};
export default CacheData;