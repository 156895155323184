const Time = {
    PickTime:{
        template:{
            group:{
                title:'预约日期',
                tabs0:'开始日期',
                tabs1:'结束日期',
            }
        },
        setup:{
            onConfirm:{
                tips:'结束时间大于起始时间'
            }
        },
    }
}
export default Time