const Form = {
    InputText:{
        template:{
            InputStr:{
                label:'操作列表',
                placeholder:'请输入等待操作的列表[一行一条]',
            },
            tag:'当前操作数量',
            button:{
                onSubmit:'执行操作',
                RefreshPage:'刷新页面',
            }
        },
        setup:{
            InitCacheUserList:{
                title:'加载数据',
                message:'检测到列表存在数据,是否需要导入?',
            },
            onSubmit:'操作列表为空',
        }
    },

    SelectLowAgent:{
        template:{
            NewAgentUserName:{
                label:'下级代理',
                placeholder:'请选择目标代理商',
            },
            SearchKey:'搜索代理商...',
        },
        setup:{
            onConfirm:'请选择要操作的下级代理商',
            onSearch:'搜索完毕! 结果数量',
        }
    },
}
export default Form