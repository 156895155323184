import { resolveComponent as _resolveComponent, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'HeaderNavBar',
  props: ['title'],
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        title: props.title,
        "left-arrow": "",
        onClickLeft: _cache[0] || (_cache[0] = () => _unref(Common_Router).Back())
      }, null, 8, ["title"]);
    };
  }
};