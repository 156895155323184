/**
 * vue router
 */
import VueRouterConfig from "@/router/VueRouterConfig";

const Common_Router = {
    /**
     * 刷新当前页面
     */
    RefreshPage(){
        VueRouterConfig.go(0);
    },

    /**
     * [后退]返回上一个页面
     */
    Back(){
        VueRouterConfig.go(-1);
    },

    /**
     * 跳转页面
     * vue-router.push
     * @param options
     * @return {Promise<NavigationFailure | void | undefined>}
     */
    push(options){
        return VueRouterConfig.push(options);
    },
    // getRoute(){
    //     return VueRouterConfig.app._route.name;
    // }
};
export default Common_Router;