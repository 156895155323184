import ComponentsLang from "@/locales/Components/ComponentsLang";

class LangsClass {
    constructor(module,name) {
        this.langs = ComponentsLang(module,name)
        console.log('this.langs',this.langs,module,name)
    }

    GetObjField(obj,field){
        let arr = field.split('.')
        let temp = obj;
        for (let arrKey in arr) {
            let str = arr[arrKey];
            temp = temp[str]|| null;
            if (!temp){
                console.error('error!!!.GetObjFile',field)
                console.log(obj,arr)
                break
            }
        }
        return temp;
    }

    GetTemplateLang(field){
        if(!this.langs.template){
            return '**!unknown';
        }
        return this.GetObjField(this.langs.template, field) || '*!unknown';
        // return '%' + this.GetObjField(this.langs.template, field) || '*!unknown';
    }

    GetSetupLang(field){
        if(!this.langs.setup) {
            return '**!unknown';
        }
        // return '#' + this.GetObjField(this.langs.setup, field) || '*!unknown';
        return this.GetObjField(this.langs.setup, field) || '*!unknown';
    }
}
export default LangsClass;