const TableNetWork = {
    TableNetWorkIndex:{
        setup:{
            HeaderOnSelect:{
                tips:'请选择要添加的项目'
            }
        }
    },

    //TableWork目录下的Table子文件夹
    TableNetWorkHeader:{
        setup:{
            InitActions:{
                success:'全选',
                cross:'取消',
                plus:'添加',
                circle:'刷新',
                apps_o:'更多',
            }
        }
    }
}
export default TableNetWork