import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "FetUserView"
};
const _hoisted_2 = {
  class: "FetUserForm"
};
import HeaderNavBar from "@/components/Header/HeaderNavBar";
import InputText from "@/components/Form/InputText";
import { onMounted, ref } from "vue";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_Agent from "@/apis/Apis_Agent";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentUserList-FetchUser',
  setup(__props) {
    const LangLib = new LangCom('Agent', 'AgentUserListFetchUser');
    // const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const Agent = ref('');
    onMounted(() => {
      let cacheStr = CacheData.GetData('AgentUserList-Agent');
      if (cacheStr) {
        Agent.value = cacheStr;
      } else {
        VantMsgLib.alertError(GetLangSetup('onMounted.error'));
      }
    });
    const InputTextOptions = {
      CacheDataField: 'AgentUserList-FetchUser',
      //缓存数据字段
      Name: GetLangSetup('InputTextOptions.Name') //[功能名字]用于提示用户是否执行该功能
    };

    const onSubmit = options => {
      let str = options.arr.toString();
      Apis_Agent.moveUserByLowAgent(str, Agent.value);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(HeaderNavBar), {
        title: InputTextOptions.Name + '[' + (Agent.value || '???') + ']'
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(InputText), {
        Options: InputTextOptions,
        onOnSubmit: onSubmit
      })])]);
    };
  }
};